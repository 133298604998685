import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import some from 'lodash/some';
import styled, { css } from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';
import ChevronDownIcon from 'components/lib/icons/chevron_down_icon';
import Checkmark from 'components/common/icons/checkmark.jsx';
import InsetContainer from 'components/common/containers/inset_container';
import PopoverMenu, { PopoverMenuItem, StyledMenuItem, usePopoverMenu } from 'components/common/menu';
import SearchInput from 'components/common/search_input';
import { useScrollDocument } from 'components/common/dropdown_menu_v2';

export { StyledMenuItem };

/**
 * @visibleName Dropdown Menu
 */

function DropdownMenu({
  'data-aid': dataAid,
  delay,
  allowEmptyValues,
  className,
  disabled,
  fitOpenerWidth,
  isError,
  isMultiSelect,
  maxHeight,
  maxRows,
  maxWidth,
  menuComponent,
  multiSelectLabel,
  onSelect,
  openerComponent,
  options,
  placeholder,
  position,
  readOnly,
  searchPlaceholder,
  searchable,
  text,
  value: rawValue,
}) {
  const { targetRef, setTargetRef, isOpen, onClose, onToggle } = usePopoverMenu();
  const [searchText, setSearchText] = useState('');
  const [sortedOptions, setSortedOptions] = useState([]);

  // if 'allowEmptyValues' is set then consider null/undefined as ''
  const value = !rawValue && allowEmptyValues ? '' : rawValue;
  useEffect(() => setSearchText(''), [isOpen]);
  useScrollDocument(isOpen, onClose);

  useEffect(() => {
    const keyDownHandler = event => {
      if (isOpen && event.key === 'Escape') {
        event.stopPropagation();
        onClose();
      }
    };
    document.addEventListener('keydown', keyDownHandler, true);
    return () => {
      document.removeEventListener('keydown', keyDownHandler, true);
    };
  }, [onClose, isOpen]);

  useEffect(() => {
    const getSortedOptions = function(unsortedOptions) {
      if (!isMultiSelect) {
        return unsortedOptions;
      }

      const selectedItems = [];
      const unselectedItems = [];
      unsortedOptions.forEach(unsortedOption => {
        if (some(value, valueItem => unsortedOption.value === valueItem)) {
          selectedItems.push(unsortedOption);
        } else {
          unselectedItems.push(unsortedOption);
        }
      });
      return [...selectedItems, ...unselectedItems];
    };
    setSortedOptions(options && getSortedOptions(options));
  }, [value, setSortedOptions, options, isOpen, isMultiSelect]);

  const filteredOptions = searchText
    ? filter(sortedOptions, option => {
        if (option.type === 'HEADER' || option.type === 'SPLITTER') {
          return false;
        }
        const optionText = option.text || option.label || option.value || '';
        return optionText?.toLowerCase()?.indexOf(searchText.toLowerCase()) > -1;
      })
    : sortedOptions;

  const possibleSelectedItems = filteredOptions.length === 0 ? options : filteredOptions;
  let selectedItem;
  if (value || allowEmptyValues) {
    selectedItem = isMultiSelect
      ? find(possibleSelectedItems, o => o.value === value[0])
      : find(possibleSelectedItems, o => o.value === value);
  }

  const getSelectedItemLabel = function() {
    const getMultiSelectLabel = function() {
      return value.length > 1 ? `${value.length} ${multiSelectLabel || 'items'} selected` : selectedItem.label;
    };
    return isMultiSelect ? getMultiSelectLabel() : selectedItem.label;
  };

  const buttonText = text ? (
    text
  ) : selectedItem ? (
    getSelectedItemLabel()
  ) : (
    <DropdownPlaceholder>{placeholder}</DropdownPlaceholder>
  );
  const chevronIcon = readOnly ? null : <ChevronIcon data-isopen={isOpen} disabled={disabled} />;

  const MenuComponent = menuComponent ? menuComponent : PopoverMenu;
  const OpenerComponent = openerComponent ? openerComponent : OpenerButton;

  return (
    <React.Fragment>
      <OpenerComponent
        className={className}
        data-aid={dataAid ? `${dataAid}-opener` : undefined}
        disabled={readOnly || disabled}
        fitOpenerWidth={fitOpenerWidth}
        isError={isError}
        onClick={onToggle}
        readOnly={readOnly}
        ref={setTargetRef}
        tabindex="0"
      >
        <ButtonTextWrapper data-aid={dataAid ? `${dataAid}-openerText` : undefined}>{buttonText}</ButtonTextWrapper>
        {chevronIcon}
      </OpenerComponent>
      <MenuComponent
        data-aid={dataAid}
        fitOpenerWidth={fitOpenerWidth}
        isMultiSelect={isMultiSelect}
        isOpen={isOpen}
        onClose={onClose}
        position={position}
        targetPosition="start"
        targetRef={targetRef}
      >
        {searchable && (
          <Search
            delay={delay}
            isMultiSelect={isMultiSelect}
            onChange={setSearchText}
            placeholder={searchPlaceholder}
          />
        )}
        <ScrollingMenu fitOpenerWidth={fitOpenerWidth} maxHeight={maxHeight} maxRows={maxRows} maxWidth={maxWidth}>
          {searchable && searchText && filteredOptions.length === 0 && (
            <NoContentWrapper>No results found. Rephrase your search and try again.</NoContentWrapper>
          )}
          {filteredOptions.map((option, idx) =>
            option.type !== 'HEADER' && option.type !== 'SPLITTER' ? (
              <MenuItem
                data-aid={`menuOption-${option.value}`}
                isFocused={
                  (value || allowEmptyValues) &&
                  (isMultiSelect ? value.some(value => option.value === value) : option.value === value)
                }
                isMultiSelect={isMultiSelect}
                key={`${option.value}_${idx}`}
                onClose={onClose}
                onSelect={onSelect}
                option={option}
              />
            ) : option.type === 'HEADER' ? (
              <MenuItemHeader key={option.label} option={option} />
            ) : (
              <SPLITTER key={option.label} />
            )
          )}
        </ScrollingMenu>
      </MenuComponent>
    </React.Fragment>
  );
}

export default styled(DropdownMenu)``;

DropdownMenu.propTypes = {
  /** Sets the support of an empty value as allowed option value, e.g. { value: '', label: 'None' } */
  allowEmptyValues: PropTypes.bool,
  className: PropTypes.string,
  'data-aid': PropTypes.string,
  disabled: PropTypes.bool,
  fitOpenerWidth: PropTypes.bool,
  /** Boolean to determine if dropdown menu should allow multiple selections */
  isMultiSelect: PropTypes.bool,
  /** Set the maximum height by setting a maximum number of rows with a number of pixel bleed over */
  maxRows: PropTypes.number,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  /** For multiselect menus, set a label to display when more than one item has been selected */
  multiSelectLabel: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.exact({
        icon: PropTypes.element,
        label: PropTypes.node.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        type: PropTypes.oneOf(['HEADER', 'SPLITTER']),
      }),
      PropTypes.exact({
        label: PropTypes.node.isRequired,
        type: PropTypes.oneOf(['HEADER', 'SPLITTER']).isRequired,
      }),
      PropTypes.exact({
        label: PropTypes.node.isRequired,
        text: PropTypes.string, // Text search (specified via searchable prop) will use this vs. value if specified
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
      }),
    ])
  ),
  placeholder: PropTypes.string, // Placeholder text for collapsed dropdown menu
  readOnly: PropTypes.bool,
  searchPlaceholder: PropTypes.string, // Placeholder text for search input if searchable is true
  searchable: PropTypes.bool,
  text: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array]),
};

DropdownMenu.defaultProps = {
  position: 'bottom',
};

export function MenuItem({ className, 'data-aid': dataAid, isFocused, isMultiSelect, onClose, onSelect, option }) {
  const onClick = useCallback(() => {
    onSelect(option.value);
    !isMultiSelect && onClose();
  }, [isMultiSelect, onClose, onSelect, option.value]);
  let refContainer = useRef(null);

  useEffect(() => {
    if (!isMultiSelect) {
      isFocused &&
        refContainer &&
        refContainer.current &&
        refContainer.current.scrollIntoView &&
        refContainer.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isFocused, isMultiSelect]);

  const content =
    option.label && option.description ? (
      <ItemWithDescription>
        <ItemHeader>{option.label}</ItemHeader>
        <ItemDescription>{option.description}</ItemDescription>
      </ItemWithDescription>
    ) : (
      <>
        {option.icon && <ItemIcon>{option.icon}</ItemIcon>}
        {option.label}
      </>
    );

  return (
    <PopoverMenuItem
      className={className}
      component={OverrideMenuItem}
      data-aid="dropdownMenuItem"
      isFocused={isFocused}
      isMultiSelect={isMultiSelect}
      onClick={onClick}
    >
      <div ref={isFocused ? refContainer : null}>
        <LabelContainer data-aid={`${dataAid}-label`}>
          {isFocused && isMultiSelect && (
            <MenuCheckMarkContainer>
              <MenuItemCheckmark data-aid={`${dataAid}-checked`} />
            </MenuCheckMarkContainer>
          )}
          {content}
        </LabelContainer>
      </div>
    </PopoverMenuItem>
  );
}

export function MenuItemHeader({ option }) {
  return (
    <PopoverMenuItem component={HeaderItem} data-aid="dropdownMenuItemHeader">
      {option.label}
    </PopoverMenuItem>
  );
}

export const ScrollingMenu = styled.div`
  max-height: ${p => p.maxHeight || p.maxRows * 30 + 18 || 250}px;
  max-width: ${({ fitOpenerWidth, maxWidth = 350 }) => (fitOpenerWidth ? '100%' : `${maxWidth}px`)};
  overflow: auto;
`;

export const OpenerButton = styled(Button).attrs({ buttonType: ButtonTypes.SECONDARY })`
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: space-between;
  ${p => (p.fitOpenerWidth ? 'width: 100%;' : '')};
  ${p => (p.readOnly ? `&[disabled] { color: ${p.theme.colors.gray800}; }` : '')};
  min-height: 34px; // the button shouldn't change its height if there's no chevron icon and selected value

  &:focus {
    border: 1px solid ${p => p.theme.colors.gray600};
  }

  ${p => (p.isError ? { 'border-color': p.theme.colors.red300 } : '')}
`;

export const ChevronIcon = styled(ChevronDownIcon)`
  stroke: ${p => (p.disabled ? p.theme.colors.gray300 : p.theme.colors.gray600)};
  fill: ${p => (p.disabled ? p.theme.colors.gray300 : p.theme.colors.gray600)};
  height: 16px;
  margin-left: ${p => p.theme.spacing.medium};
  width: 13px;
  line-height: 0;

  ${p => p['data-isopen'] && flipChevron};
`;

const MenuItemCheckmark = styled(Checkmark)`
  height: 16px;
  width: 16px;
`;

const MenuCheckMarkContainer = styled.div`
  margin: 0 ${p => p.theme.spacing.medium} 0 ${p => p.theme.spacing.large};
`;

const ItemIcon = styled.div`
  padding-right: ${p => p.theme.spacing.insetSmall};
`;

const SPLITTER = styled.div`
  border-bottom: 1px solid ${p => p.theme.colors.gray300};
  margin: 4px 0px;
  height: 1px;
`;

const ItemWithDescription = styled.div``;
ItemWithDescription.displayName = 'ItemWithDescription';

const ItemHeader = styled.div`
  font-weight: 700;
`;
ItemHeader.displayName = 'ItemHeader';

const ItemDescription = styled.span``;
ItemDescription.displayName = 'ItemDescription';

export const LabelContainer = styled.div`
  display: flex;
`;

export const ButtonTextWrapper = styled.div`
  display: block;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 20px);
`;

const flipChevron = css`
  transform: rotate(-180deg);
`;

const OverrideMenuItemSingleSelectStyle = css`
  padding: 8px 24px;
`;

const OverrideMenuItemMultiSelectStyle = css`
  padding: ${p => p.theme.spacing.medium} 40px ${p => (p.isFocused ? p.theme.spacing.small : p.theme.spacing.medium)}
    ${p => (p.isFocused ? '0' : '40px')};
`;

const OverrideMenuItem = styled(StyledMenuItem)`
  ${p => (p.isMultiSelect ? OverrideMenuItemMultiSelectStyle : OverrideMenuItemSingleSelectStyle)};
`;

const HeaderItem = styled(InsetContainer)`
  color: ${p => p.theme.colors.gray600};
  font-size: ${p => p.theme.fontSize.small};
  font-weight: bold;
  min-width: 100px;
  padding: 8px 16px 4px 16px;
`;

export function Search({ delay, isMultiSelect, onChange, placeholder }) {
  const actualOnChange = useCallback(evt => onChange(evt.target.value), [onChange]);
  return (
    <SearchWrapper isMultiSelect={isMultiSelect}>
      <SearchInput delay={delay} onChange={actualOnChange} placeholder={placeholder} />
    </SearchWrapper>
  );
}

const SearchWrapperSingleSelectStyle = css`
  border-bottom: 1px solid ${p => p.theme.colors.gray300};
  padding: 16px;
`;

const SearchWrapperMultiSelectStyle = css`
  padding: ${p => p.theme.spacing.medium} ${p => p.theme.spacing.medium} ${p => p.theme.spacing.small}
    ${p => p.theme.spacing.medium};
`;

const SearchWrapper = styled.div`
  ${p => (p.isMultiSelect ? SearchWrapperMultiSelectStyle : SearchWrapperSingleSelectStyle)};
`;

const NoContentWrapper = styled.div`
  padding: 8px 16px;
  text-align: center;
  width: 100%;
  word-wrap: normal;
`;

export const DropdownPlaceholder = styled.span`
  color: ${p => p.theme.colors.gray700};
`;

NoContentWrapper.displayName = 'NoResultsContainer';
